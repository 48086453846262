import { useBreakpointIndex } from '@theme-ui/match-media';
import { motion } from 'framer-motion';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dispatch, SetStateAction } from 'react';

import {
  Button,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Spacer,
  Text,
  useThemeUI,
} from 'voom-gatsby';

import { ConsultationTag } from '~components';

import { ReactComponent as GeoMarkerIcon } from '~svg/icons/icon-geomarker.svg';

import { DoctorResult } from '~types/sanity';

export const DoctorResultCard = ({
  doctor,
  additionalLocationCount,
  selectedIndex,
  setSelectedIndex,
  index,
}: {
  doctor: DoctorResult;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  additionalLocationCount: number;
  index: number;
}) => {
  const active = index === selectedIndex;

  const breakpointIndex = useBreakpointIndex();
  const { theme } = useThemeUI();

  return (
    <motion.div
      onMouseEnter={() => setSelectedIndex(index)}
      data-id={index}
      variants={{
        default: {
          y: 0,
          // Theme error event though doctorResultsShadow exists
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boxShadow: theme.shadows?.['doctorResultsShadow'],
        },
        active: {
          y: -5,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boxShadow: theme.shadows?.['doctorResultsActiveShadow'],
        },
      }}
      animate={active ? 'active' : 'default'}
      sx={{
        height: [null, null, null, 280],
        my: 3,
        borderRadius: 'corner',
        overflow: 'hidden',
        textDecoration: 'none',
      }}
    >
      <Link
        to={`/find-a-doctor/doctors/${doctor.slug.current}`}
        variant="ghost"
        sx={{ textDecoration: 'none' }}
      >
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, '215px 1fr', null, '375px 1fr'],
            gridGap: 0,
          }}
        >
          <GatsbyImage
            image={doctor.image.asset.gatsbyImageData}
            alt={doctor.image.alt}
            sx={{
              aspectRatio: ['325 / 210', null, '215 / 280', null, '375 / 280'],
              width: 'full',
              maxWidth: 'full',
              img: {
                borderBottomLeftRadius: [null, null, 'corner'],
                borderTopRightRadius: ['corner', null, 'square'],
                borderTopLeftRadius: 'corner',
                objectPosition: 'top',
              },
            }}
          />
          <Grid
            sx={{
              width: 'full',
              gridTemplateAreas: [
                "'eyebrow' 'title' 'consultations' 'links'",
                null,
                "'eyebrow consultations' 'title title' 'links links'",
              ],
              gridTemplateColumns: [
                '1fr',
                null,
                '1fr minmax(min-content, max-content)',
              ],
              gridTemplateRows: [
                null,
                null,
                null,
                'minmax(min-content, max-content)',
              ],
              gridGap: [2, null, null, 0],
              p: 4,
              pb: [5, null, null, 4],
            }}
          >
            <Paragraph variant="mediumP" sx={{ gridArea: 'eyebrow', mb: 1 }}>
              {doctor.distance && (
                <>
                  <GeoMarkerIcon sx={{ mr: 2, width: 16, height: 16 }} />
                  <Text
                    sx={{ fontWeight: 'medium' }}
                  >{`${doctor.distance.toFixed(
                    doctor.distance < 20 ? 1 : 0,
                  )} mi.`}</Text>
                  <Text
                    sx={{
                      mx: 2,
                      borderRight: (theme) =>
                        `1px solid ${theme.colors?.grey300}`,
                    }}
                  />
                </>
              )}
              <Text>{`${doctor.city.name}, ${doctor.state.abbreviation}`}</Text>
              {additionalLocationCount > 0 && (
                <>
                  <Text
                    sx={{
                      mx: 2,
                      borderRight: (theme) =>
                        `1px solid ${theme.colors?.grey300}`,
                    }}
                  />
                  <Text>{`${additionalLocationCount} Additional Location${
                    additionalLocationCount > 1 ? 's' : ''
                  }`}</Text>
                </>
              )}
            </Paragraph>
            <Flex
              sx={{
                gridArea: 'title',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Heading
                variant="heading"
                sx={{
                  fontSize: ['xl', null, null, '2xl', '4xl'],
                  '@media (hover: hover)': {
                    ':hover span': {
                      textDecoration: 'underline',
                    },
                  },
                }}
              >
                <Text
                  sx={{
                    fontWeight: 'medium',
                    mr: 2,
                  }}
                >
                  {doctor.name}
                </Text>
                {doctor.credentials && <Text>{doctor.credentials}</Text>}
              </Heading>
              <Spacer space={2} />
              <Paragraph
                variant="mediumP"
                sx={{ mb: 0, span: { color: 'grey500' } }}
              ></Paragraph>
            </Flex>
            <Flex
              sx={{
                flexDirection: ['row', null, null, 'column', 'row'],
                gridArea: 'consultations',
                alignItems: [
                  'flex-start',
                  null,
                  null,
                  'flex-end',
                  'flex-start',
                ],
                justifyContent: [null, null, null, null, 'flex-end'],
              }}
            >
              {doctor.consultations.map((consultation) => (
                <ConsultationTag
                  key={consultation}
                  consultation={consultation}
                  sx={{
                    bg: 'tan',
                    mb: [null, null, null, 2, 0],
                    mr: [2, null, null, 0, 2],
                  }}
                />
              ))}
            </Flex>
            <Flex
              sx={{
                gridArea: 'links',
                flexDirection: ['column', null, 'row'],
                alignItems: ['flex-start', null, 'center'],
                justifyContent: 'flex-start',
                gap: 3,
                mt: [4, null, 'auto'],
              }}
            >
              <Button
                onClick={(e) => {
                  // This must be a button because its inside a link, we override the link behavior
                  // and send users to the doctor page and open the contact form
                  e.preventDefault();
                  navigate(
                    `/find-a-doctor/doctors/${doctor.slug.current}?contact=true`,
                  );
                }}
                sx={{ width: ['full', null, 'auto'] }}
              >
                Contact This Doctor
              </Button>
              <Button
                variant={breakpointIndex >= 2 ? 'navButton' : 'outline'}
                sx={{ width: ['full', null, 'auto'] }}
              >
                <Text>View Profile</Text>
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </Link>
    </motion.div>
  );
};
